import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFacebookF } from 'react-icons/all'
import classNames from 'classnames'
import Link from '../Elements/Link/Link'
import * as styles from './Footer.module.scss'

export default () => (
	<footer className={styles.footer}>
		<div className={styles.content}>
			<Container>
				<Row>
					<Col lg={4} className='d-none d-lg-block'>
						<span className={styles.title}>Apie mus</span>
						<p>
							Mūsų patirtis suteikė galimybę pažvelgti plačiau į transporto ir logistikos sferą.
							Spektro servisas ne tik pasirūpins Jūsų krovinių transportavimu iš taško A į tašką B,
							bet taip pat pasirūpins Jūsų transporto priemonės priežiūra ir taisymu. Mes Jums
							galime pasiūlyti sunkiujų transpoto priemonių, priekabų, puspriekabių bei bei cisternų
							taisyma ir prekiausime atsarginėmis dalimis.
						</p>
					</Col>
					<Col lg={4} sm={6} xs={12} className='d-none d-sm-block'>
						<span className={styles.title}>Navigacija</span>
						<ul className={styles.list}>
							<li>
								<Link url='/' text='Pagrindinis' gatsby />
							</li>
							<li>
								<Link url='/service' text='Servisas' gatsby />
							</li>
							<li>
								<Link url='/logistics' text='Logistika' gatsby />
							</li>
							{/* <li> */}
							{/*	<Link url='/contacts' text='Kontaktai' /> */}
							{/* </li> */}
							{/* <li> */}
							{/*	<Link url="#" text="Dalys" /> */}
							{/* </li> */}
							{/* <li> */}
							{/*	<Link url="#" text="Plovykla" /> */}
							{/* </li> */}
							{/* <li> */}
							{/*	<Link url="#" text="Naftors produktai" /> */}
							{/* </li> */}
							{/* <li> */}
							{/*	<Link url="#" text="Privatumo politika" /> */}
							{/* </li> */}
							{/* <li> */}
							{/*	<Link url="#" text="Slapukai" /> */}
							{/* </li> */}
						</ul>
					</Col>
					<Col lg={4} sm={6} xs={12}>
						<span className={styles.title}>Kontaktai</span>
						<ul className={classNames(styles.list, styles.address)}>
							<li>J. Dobkevičiaus g. 10, Vilnius</li>
							<li>Vilnius</li>
							<li>Lietuva</li>
							<li>LT-02189</li>
							<li>
								<Link
									url='https://www.google.com/maps/place/Spektro+servisas/@54.6426623,25.2650656,17z/data=!3m1!4b1!4m6!3m5!1s0x46dded2d8211cb9f:0xda4b6a381925ec36!8m2!3d54.6426592!4d25.2676405!16s%2Fg%2F11nxq54sv_?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D'
									text='Atidaryti žemėlapį'
									newTab
								/>
							</li>
						</ul>
						<ul className={styles.list}>
							<li>
								T: <Link url='tel:+370 650 90303' text='+370 650 90303' />
							</li>
							<li>
								E:{' '}
								<Link
									url='mailto:servisas@spektroservisas.lt'
									text='servisas@spektroservisas.lt'
									newTab
								/>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</div>
		<div className={styles.socialContainer}>
			<Container>
				<div className={styles.socialWrapper}>
					<span className={styles.socialTitle}>Sekite mus</span>
					<div>
						<a
							className={styles.socialLink}
							href='https://www.facebook.com/Spektro-Servisas-107720404912867'
							target='_blank'
							rel='noopener noreferrer'
						>
							<FaFacebookF className={classNames(styles.socialIcon, styles.facebook)} />
						</a>
					</div>
				</div>
			</Container>
		</div>
		<div className={styles.copyright}>2021 © UAB Spektro Servisas. Visos teisės saugomos</div>
	</footer>
)
