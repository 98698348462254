import React from 'react'
import { MapContainer, Marker, Popup } from 'react-leaflet'
import { Container } from 'react-bootstrap'
import Seo from '../components/Seo/Seo'

interface Props {
	location: Location
}

export default ({ location }: Props) => (
	<div className='pt-5'>
		<Seo title='Logistika' pathName={location.pathname} />
		<Container style={{ height: '500px' }}>
			<MapContainer center={[54.61130461328619, 25.157007925993664]} zoom={15} scrollWheelZoom>
				<Marker position={[54.61130461328619, 25.157007925993664]}>
					<Popup>
						A pretty CSS3 popup. <br /> Easily customizable.
					</Popup>
				</Marker>
			</MapContainer>
		</Container>
	</div>
)
